<!-- src/components/Signup.vue -->

<template src="@/../../../templates/brand/components/templates/user/Signup.html"> </template>

<script>
import _ from 'lodash/fp'
import { v4 as uuid } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import validator from 'validator'
import purify from 'dompurify'
const _blacklist = '\\[?\$\_\{\}\[\\]\%\=\<\>\~\`\`\\\/"' // eslint-disable-line no-useless-escape
// import { PUBLIC_URL } from '@/util/common-constants'
// import _c from '@/../../../templates/site/UI/common/common-constants'

export default {
  created () {
    // if store config for onboarding requires user to select location
    // this.$http
    //   .get(`${PUBLIC_URL}/locations`)
    //   .then(res => (this.locations = res.data))
    //   .catch(err => {
    //     console.error(' :: error fetching public locations', err)
    //   })
  },
  data () {
    return {
      locations: [],
      submitted: false,
      n1: Math.round(Math.random() * 10 + 1),
      n2: Math.round(Math.random() * 10 + 1),
      schmanser: 0,
      url: '', // NB: this is a trap
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        userType: '',
        profile: {
          first: '',
          last: '',
          phone: '',
          extradata: { dealer: '', dealerCode: 0 },
          billing: {
            aid: uuid(),
            country: 'ca',
            street: '',
            city: '',
            prov: '',
            apt: '',
            code: ''
          }
        }
      },
      err: false,
      msg: '',
      emailvalid: true,
      unamevalid: true,
      provOptions: [
        { text: 'Alberta', value: 'AB' },
        { text: 'British Columbia', value: 'BC' },
        { text: 'Manitoba', value: 'MB' },
        { text: 'New Brunswick', value: 'NB' },
        { text: 'Newfoundland and Labrador', value: 'NL' },
        { text: 'Nova Scotia', value: 'NS' },
        { text: 'Northwest Territories', value: 'NT' },
        { text: 'Nunavut', value: 'NU' },
        { text: 'Ontario', value: 'ON' },
        { text: 'Prince Edward Island', value: 'PE' },
        { text: 'Quebec', value: 'QC' },
        { text: 'Saskatchewan', value: 'SK' },
        { text: 'Yukon', value: 'YT' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    invalid: function () {
      return !(
        this.credentials.email &&
        this.emailvalid &&
        this.isEmail(this.credentials.email) &&
        this.credentials.profile.first &&
        this.credentials.profile.last &&
        this.credentials.profile.billing.street &&
        this.credentials.profile.billing.city &&
        this.credentials.profile.billing.prov &&
        this.credentials.profile.billing.code &&
        this.credentials.profile.phone &&
        (this.isAssociate(this.credentials.userType) ? this.credentials.profile.extradata.dealer : true) &&
        this.n1 + this.n2 === (Number)(this.schmanser) &&
        !this.url
      )
    }
  },
  methods: {
    ...mapActions({ dologin: 'completeLogin'}), // not sure if this is correct, copied from Login.vue
    isAlpha: function (val) {
      return validator.isAlpha(val)
    },
    isAlphaNum: function (val) {
      return validator.isAlphanumeric(val)
    },
    isEmail: function (val) {
      return validator.isEmail(val)
    },
    isEmpty: function (val) {
      return validator.isEmpty(val)
    },
    isDealer: function (val) {
      return !!~['ho', 'as'].indexOf(val)
    },
    isAssociate: function (val) {
      return !!~['ds', 'dm'].indexOf(val)
    },
    mask: function (evt) {
      const _path = evt.target.dataset.index // console.info('masking',evt,' checking ',_path, this.credentials)
      let _newval = purify.sanitize(evt.target.value)
      if (_newval) {
        _newval = validator.blacklist(_newval, _blacklist)
      } else {
        _newval = ''
      }
      evt.target.value = _newval

      const _parts = _path.split('.') // console.info('PARTS: ',_parts)
      let _prop = this.credentials
      for (let i = 0, c = _parts.length; i < c; i++) {
        if (i === c - 1) _prop[_parts[i]] = _newval
        else _prop = _prop[_parts[i]]
      }
    },
    sanitize () {
      const _out = _.assign({}, this.credentials)
      _out.email = validator.normalizeEmail(_out.email.trim().toLowerCase())
      _out.profile.first = validator.blacklist(
        purify.sanitize(_out.profile.first, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.last = validator.blacklist(
        purify.sanitize(_out.profile.last, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.phone = validator.blacklist(
        purify.sanitize(_out.profile.phone, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.city = validator.blacklist(
        purify.sanitize(_out.profile.billing.city, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.code = validator.blacklist(
        purify.sanitize(_out.profile.billing.code, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.street = validator.blacklist(
        purify.sanitize(_out.profile.billing.street, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.billing.apt = validator.blacklist(
        purify.sanitize(_out.profile.billing.apt, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.extradata.dealer = validator.blacklist(
        purify.sanitize(_out.profile.extradata.dealer, { ALLOWED_TAGS: [] }),
        _blacklist
      )
      _out.profile.email = validator.normalizeEmail(_out.email)
      _out.profile.lang = this.$i18n.locale
      return _out
    },
    submit: function () {
      if (this.invalid || this.submitted) return false
      this.credentials.profile.lang = this.$i18n.locale
      this.submitted = true
      this.$http
        .post(`public/users/signup?approval=${!this.appconfig.siteConfig.noApproval}`, this.credentials)
        // signup(this.sanitize(this.credentials))
        .then(res => {
          if (res.data.success) {
            this.msg = 'success'
          } else if (res.data.msg) {
            this.msg = res.data.msg
          } else {
            this.msg = res.data.messages
          }
        })
        .catch(err => {
          this.err = true
          this.msg = err
        })
    },
    testEmail: function () {
      // console.info(' ::::: email url', _c)
      if (!this.credentials.email) {
        return
      }
      this.$http
        .get(
          `public/users/validemail/${this.credentials.email.trim().toLowerCase()}`
        )
        .then(res => {
          if (typeof res.data === 'string' || _.isEmpty(res.data)) {
            this.emailvalid = true
          } else {
            this.emailvalid = false
          }
        })
        .catch(err => {
          console.error('got an error', err)
        })
    },
    testUsername: function () {
      if (!this.credentials.username) {
        return
      }
      this.$http
        .get(`public/users/validusername/${this.credentials.username}`)
        .then(res => {
          if (_.isEmpty(res.data)) {
            this.unamevalid = true
          } else {
            this.unamevalid = false
          }
        })
        .catch(err => {
          console.error('got an error', err)
        })
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/Signup.css"></style>
